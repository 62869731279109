export default [
    {
        id: 1,

        className: "accordion-item mx-4 sm:mx-auto cards sm:me-2 bg-[rgba(210,199,199,0.15)]",
        
        projectTitle: "FLOW 1",

        subtitle: "Pipper",

        descriptionStyles: "w-fit font-semibold text-xl",

        descriptionOne: "",
        descriptionTwo: "",
        descriptionThree: "",
        descriptionFour: "hidden",

        description:
        "Vores første projekt på Frontend-linjen gik ud på, at vi skulle lave en klon af Twitter, Pipper.",

        description2: "Da det var vores første projekt, og første gang vi for alvor skulle arbejde med JavaScript, MySQL, PHP osv., så blev det en meget simplificeret version.",

        description3: "Tjek vores kode ud nedenfor!",

        description4: "",


        imageUrl: "flow11img.png",

        link: "/",

        link2: "https://drive.google.com/file/d/1r15Y7JsoYM50l3_beHjXybOOwkjd0Bu6/view?usp=sharing",

        classNameButton: "flex sm:mt-[0px] xl:mt-[104px] semester-3-flow-1",

        classNameButton1: "accordion-button me-1 hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)] hidden",

        classNameButton2: "accordion-button ms-1 hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)] button-padding",

        button: "",

        button2: "GitHub Kode"
    },
    {
        id: 2,

        className: "accordion-item mx-4 sm:mx-auto sm:ms-2 cards bg-[rgba(210,199,199,0.15)]",
        
        projectTitle: "FLOW 2",

        subtitle: "3 Små React Projekter",

        descriptionStyles: "w-fit font-semibold text-xl",

        descriptionOne: "",
        descriptionTwo: "",
        descriptionThree: "",
        descriptionFour: "",

        description:
        "Vi skulle lave 3 små projekter i React ud fra det underviste materiale, som components osv.",

        description2: "- Omskriv Pipper-projektet i React",

        description3: "- Kontaktbog",

        description4: "- Tøjbutik",


        imageUrl: "flow12img.png",

        link: "/",

        link2: "https://drive.google.com/file/d/1eZu09flrdJ2WxzD0GT4pVmXbLmCEP4VK/view?usp=sharing",

        classNameButton: "flex sm:mt-[0px]",

        classNameButton1: "accordion-button me-1 hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)] hidden",

        classNameButton2: "accordion-button ms-1 hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)] button-padding md:mt-[120px]",

        button: "",

        button2: "Zip-fil med 3 projekter"
    },
    {
        id: 3,

        className: "accordion-item mx-4 sm:mx-auto cards sm:me-2 bg-[rgba(210,199,199,0.15)]",
        
        projectTitle: "Valgfag eksamen",

        subtitle: "Visuel identitet og grafisk design",

        descriptionStyles: "w-fit font-semibold text-xl",

        descriptionOne: "",
        descriptionTwo: "",
        descriptionThree: "",
        descriptionFour: "",

        description:
        "Vi skulle udvikle et forslag til identitet- og præsentationsmateriale til en virksomhed, der skal opføre boliger på Lynetteholmen.",

        description2: "",

        description3: "Det blev til Skovholmen. Se vores bud nedenfor!",

        description4: "Vi fik 7 for dette projekt.",


        imageUrl: "flow13img.png",

        link: "/",

        link2: "https://drive.google.com/file/d/1d9BTbEltZ1S7bJs8zw3cLpwbuTSj0roW/view?usp=sharing",

        classNameButton: "flex sm:mt-[0px]",

        classNameButton1: "accordion-button me-1 hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)] hidden",

        classNameButton2: "accordion-button ms-1 hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)] button-padding",

        button: "",

        button2: "Skovholmen Styleguide"
    },
    {
        id: 4,

        className: "accordion-item mx-4 sm:mx-auto sm:ms-2 cards bg-[rgba(210,199,199,0.15)]",
        
        projectTitle: "3 Semester Eksamen",

        subtitle: "Frontend / UX Samarbejde",

        descriptionStyles: "w-fit font-semibold text-xl",

        descriptionOne: "",
        descriptionTwo: "invisible",
        descriptionThree: "hidden",
        descriptionFour: "hidden",

        description:
        "Igangværende",

        description2: "Vi skulle udvikle et forslag til identitet- og præsentationsmateriale til en virksomhed, der skal opføre boliger på Lynetteholmen.",

        description3: "",

        description4: "",


        imageUrl: "flow14img.png",

        link: "/",

        link2: "https://drive.google.com/file/d/1d9BTbEltZ1S7bJs8zw3cLpwbuTSj0roW/view?usp=sharing",

        classNameButton: "flex -mb-[80px] sm:mt-[0px]",

        classNameButton1: "accordion-button me-1 hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)] hidden",

        classNameButton2: "accordion-button ms-1 hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)] hidden",

        button: "",

        button2: ""
    },
]