
export default [
    {
        id: 1,

        className: "accordion-item mx-4 sm:mx-auto  cards sm:me-2 bg-[rgba(210,199,199,0.15)]",
        
        projectTitle: "FLOW 1",

        subtitle: "Billedbehandling og website prototype",

        descriptionStyles: "w-fit font-semibold text-xl",

        descriptionOne: "",
        descriptionTwo: "",
        descriptionThree: "",
        descriptionFour: "",
        
        description:
        "Vi blev først sendt ud i grupper i København, hvor vi skulle tage billeder. Herefter skulle vi vælge et billede, vi skulle redigere i Photoshop.",

        description2: "Derudover skulle vi i gruppen lave en prototype i Figma, med vores billeder, til et selvopfunden event for studerende.",

        description3: "Se vores bud i nedenstående link.",

        imageUrl: "flow1img.png",

        link: "https://drive.google.com/file/d/1yF-gttXfLVs6Y37vck6u37czuex0J5jQ/view?usp=sharing",

        link2: "https://drive.google.com/file/d/1twWMLX7aKF2FN4EeozVewIT5AqxAwBum/view?usp=sharing",

        classNameButton: "grid grid-cols-2",

        classNameButton1: "accordion-button me-1 hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)]",

        classNameButton2: "accordion-button ms-1 hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)]",

        button: "Photoshop billede",

        button2: "Projekt Rapport"
    },

    {
        id: 2,

        className: "accordion-item mx-4 sm:mx-auto sm:ms-2 cards bg-[rgba(210,199,199,0.15)]",
        
        projectTitle: "FLOW 2",

        subtitle: "Visuelt koncept til digitale og printede medier",

        descriptionStyles: "w-fit font-semibold text-xl",

        descriptionOne: "",
        descriptionTwo: "",
        descriptionThree: "",
        descriptionFour: "",

        description:
        "Vi skulle i grupper vælge en eksisterende kunstner/musiker vi fandt inspirerende, og med inspiration fra tidligere albums, skulle vi skabe et visuelt univers, til en mulig fremtidig udgivelse.",

        description2: "",

        description3: "",

        description4: "",


        imageUrl: "flow2img.png",

        link: "https://drive.google.com/file/d/1l_sUFAvkDCOPAuzY5fvG3jQW1mMIhGwn/view?usp=sharing",

        link2: "https://www.figma.com/proto/ecKG9DzQpd3An8Qr6iUqtr/Drake-website?node-id=216%3A50&scaling=min-zoom&page-id=0%3A1&starting-point-node-id=216%3A50&show-proto-sidebar=1",

        classNameButton: "grid grid-cols-2 sm:mt-[0px] md:mt-[105px] semester-1-flow-2",

        classNameButton1: "accordion-button me-1 hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)] !xl:py-0 !pt-[20px]",

        classNameButton2: "accordion-button ms-1 hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)]",

        button: "Moodboard",

        button2: "Figma Prototype"
    },
    {
        id: 3,

        className: "accordion-item mx-4 sm:mx-auto cards sm:me-2 bg-[rgba(210,199,199,0.15)]",
        
        projectTitle: "FLOW 3",

        subtitle: "Byg dit første website",

        descriptionStyles: "w-fit font-semibold text-xl",

        descriptionOne: "",
        descriptionTwo: "",
        descriptionThree: "",
        descriptionFour: "hidden",

        description:
        "Vi fik til opgave at vælge en politiker op til valgkampen, hvorefter vi skulle kode vores første website fra bunden, der også skulle fungere som en landingpage til den valgte poltiker.",
        
        

        description2: "Jeg valgte Alex Vanopslagh, da jeg synes han var meget oppe i medierne på daværende tidspunkt, og man derfor kunne lave noget fedt og moderne med ham.",

        description3: "Se mit bud på en landingpage til ham, ved at klikke på linket.",

        description4: "",


        imageUrl: "flow3img.png",

        link: "https://www.casperpedersen.com/alex-vanopslagh/forside.html",

        link2: "",

        classNameButton: "flex sm:mt-[0px]",

        classNameButton1: "accordion-button me-1 hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)] button-padding",

        classNameButton2: "accordion-button ms-1 hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)] hidden",

        button: "Landingpage",

        button2: ""
    },
    {
        id: 4,

        className: "accordion-item mx-4 sm:mx-auto sm:ms-2 cards bg-[rgba(210,199,199,0.15)]",
        
        projectTitle: "FLOW 4",

        subtitle: "SoMe video produktion",

        descriptionStyles: "w-fit font-semibold text-xl",

        descriptionOne: "",
        descriptionTwo: "",
        descriptionThree: "",
        descriptionFour: "hidden",

        description:
        "Vi skulle vælge et firma der kørte en form for Cirkulær Økonomi. Min gruppe valgte derfor Carlsberg",
        
        

        description2: "Her skulle vi så udarbejde tre kampagne videoer, der var henholdsvis 5 sekunder, 15 sekunder og 30 sekunder",

        description3: "Se vores kampagnevideoer og landingpage i nedenstående link",

        description4: "",


        imageUrl: "flow4img.png",

        link: "https://casperpedersen.com/carlsberg%20/index.html",

        link2: "",

        classNameButton: "flex sm:mt-[100px] xl:mt-[80px]",

        classNameButton1: "accordion-button me-1 hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)] button-padding",

        classNameButton2: "accordion-button ms-1 hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)] hidden",

        button: "Landingpage",

        button2: ""
    },
    {
        id: 5,

        className: "accordion-item xl:w-[99%] mx-4 sm:mx-auto cards sm:me-2 bg-[rgba(210,199,199,0.15)]",
        
        projectTitle: "FLOW 5",

        subtitle: "Eksamen",

        descriptionStyles: "w-fit font-semibold text-xl",

        descriptionOne: "",
        descriptionTwo: "",
        descriptionThree: "",
        descriptionFour: "",

        description:
        "I vores eksamens flow havde vi to fokusområder;",
        
        

        description2: "1. Design af portfolio",

        description3: "2. Indhold til portfolio",

        description4: "Klik på nedenstående link, for at læse nærmere omkring historien bag.",


        imageUrl: "flow5img.png",

        link: "https://casperpedersen.com/1sem-eksamen/historien-bag.html",

        link2: "",

        classNameButton: "flex sm:mt-[0px]",

        classNameButton1: "accordion-button me-1 hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)] button-padding",

        classNameButton2: "accordion-button ms-1 hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)] hidden",

        button: "Historien Bag",

        button2: ""
    },
    
    
    
]