import React from 'react'
import '../styles/about.css'
import aboutme from '../assets/aboutme.png'

const About = () => {
  return (
    <section name='about' id='about' className='mb-52'>


      <div className='px-8 lg:px-36 xl:px-96'>

          <h1 className='section-title mx-auto'>About</h1>

                <p className='tracking-normal font-bold text-lg  text-left pt-5 lg:mx-auto lg:text-center lg:pb-10 lg:text-xl xl:text-xl'>
                  Hi. I'm Casper, 23 year old Multimediadesign student. 👨‍🎓
                  <br/>
                  Take a look around, or read more about me here.
                </p>
            <div className='grid lg:grid-cols-2 w-full'>
            
              <div className='lg:pe-4'>
                    <p className='tracking-normal text-base pt-5 text-left xl:text-lg'>
                      I am currently enrolled at CPH Business in Lyngby, where I have chosen <span className='italic font-semibold'>'Frontend-Developer'</span> as my major subject. 👨‍💻
                      <br/>
                      Here I developed a thing for programming and web development, so when I have sparetime, I am always trying to expand my skills, whether it is taking online courses or just building different projects. 💻
                    
                    <br/>
                    <br/>
                      <img src={aboutme} alt='' className='min-lg:hidden lg:hidden lg:py-3 italic about-me-img w-full pb-3'>
                      </img>
                      <span className=''>
                      Outside of my studies I love to hang out with my family & friends. It can be at the movies, playing padeltennis, watching sports or even sometimes out partying. 😃
                      </span>
                    </p>
              </div>
                    <div>
                      <img src={aboutme} alt='' className='max-lg:hidden lg:py-3 italic about-me-img w-full pt-5 xl:h-[400px]'>
                      </img>
                    </div>
          </div>
        </div>
    </section>
  )
}

export default About