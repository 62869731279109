import React from 'react'
import '../styles/projects.css'
import Semester1Items from './1SemesterItems'
import Semester2Items from './2SemesterItems'
import Semester3Items from './3SemesterItems'
import Semester1Data from '../data/1SemesterData'
import Semester2Data from '../data/2SemesterData'
import Semester3Data from '../data/3SemesterData'





const SchoolProjects = () => {

    const Semester1Cards = Semester1Data.map(item => {
        return (
        <Semester1Items
                  key={item.id}
                  {...item}
        />
        )
      })
     const Semester2Cards = Semester2Data.map(item => {
         return (
         <Semester2Items
                   key={item.id}
                   {...item}
         />
         )
       })
     const Semester3Cards = Semester3Data.map(item => {
         return (
         <Semester3Items
                   key={item.id}
                   {...item}
         />
         )
       })
    
  return (
    <section className=' text-white'>
        
        <div className="section-title">
                <h1 className='leading-none'>School Projects</h1>
        </div>


        <div className='semester-items'>

                <h1 className='mt-10 mb-5 uppercase font-bold text-2xl'>1 semester</h1>


                <div className='grid sm:mx-10 sm:grid-cols-2 xl:grid-cols-2 xl:me-'>
                    {Semester1Cards}
                </div>

                <h1 className='mt-10 mb-5 uppercase font-bold text-2xl'>2 semester</h1>

                <div className='grid sm:mx-10 sm:grid-cols-2'>
                    {Semester2Cards}
                </div>

                <h1 className='mt-10 mb-5 uppercase font-bold text-2xl'>3 semester</h1>

                <div className='grid sm:mx-10 sm:grid-cols-2'>
                    {Semester3Cards}
                </div>
                

        </div>




        
    </section>
    
  )
}

export default SchoolProjects