import React from 'react'

const ProjectCard = (props) => {
  return (
    <div class="card cards md:mx-4 bg-[rgba(210,199,199,0.15)]"  >

            <img src={require(`../assets/${props.imageUrl}`)} class="max-w-sm p-3" alt=""></img>

              <div className="text-white">

                  <h3 className="py-3 text-lg font-bold">{props.projectTitle}</h3>

                  <p className="tags m-auto overflow-hidden italic">

                    <span className="tag">{props.tag}</span>

                    <span className="tag">{props.tag1}</span>
                    
                  </p>

                  <a href={props.link} target="_blank" rel="noreferrer" className="project-button hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)]">{props.button}</a>

              </div>
      </div>
  )
}

export default ProjectCard