import React from "react";
import data from '../data/ProjectData'
import ProjectCard from "./ProjectCard";


export default function Projects () {

     const cards = data.map(item => {
         return (
         <ProjectCard
                   key={item.id}
                   {...item}
         />
         )
       })
    
    return (
        <>

        <section name='work' id="projects">

            <div className="section-title">
                <h1>Projects</h1>
            </div>


            <div className="grid md:grid-cols-2 md:mt-0 mt-20 md:-mb-36 lg:mb-12">{cards}</div>

            <div className="school-projects lg:mb-36">

            

                <a href="school-projects" target="_blank" rel="noreferrer" className="project-button hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)]">View School Projects </a>
                
            </div>
            
            
        </section>
        </>
    )
}