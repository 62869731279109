import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createBrowserRouter, RouterProvider} from 'react-router-dom'
import SchoolProjects from './components/School-Projects';


const router = createBrowserRouter([
  {
    path: '/',
    element: <App/>,
  },
  {
    path: 'school-projects',
    element: <SchoolProjects/>,
  }
])


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router}/>
);
