import './App.css';
import Navbar from './components/Navbar'
import Home from './components/Home'
import SchoolProjects from './components/School-Projects'
import { Routes, Route} from 'react-router-dom'

function App() {
  return (
      <div className="App">
        <Navbar />
        <Routes>
          <Route path='/'element={<Home/>}/>
          <Route path='school-projects'element={<SchoolProjects/>}/>
        </Routes>

      </div>
  );
}

export default App;
