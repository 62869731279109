import React, { useState } from 'react'
import pdf from '../assets/resume.pdf';
import '../styles/navbar.css';
import {FaBars,
    FaTimes,
    FaGithub,
    FaLinkedin,} from 'react-icons/fa'
import { HiOutlineMail } from 'react-icons/hi';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import {Link} from 'react-scroll'

const Navbar = () => {
    const [nav, setNav] = useState(false);
    const handleClick = () => setNav(!nav);
  
    return (
      <div className='fixed w-full h-[80px] flex justify-between items-center  bg-[#0a192f] text-gray-300'>
        <div>
          
        </div>
  
        {/* menu */}
        <ul className='hidden md:flex md:mx-auto md:hover:text-[var(--bgHover)]  cursor-pointer'>
          <li>
            <Link className='px-3 hover:text-[var(--bgHover)] hover:active' to='home' smooth={true} duration={200}>
              Home
            </Link>
          </li>
          <li>
            <Link className='px-3 hover:text-[var(--bgHover)] hover:active' to='about' smooth={true} duration={200}>
              About
            </Link>
          </li>
          <li>
            <Link className='px-3 hover:text-[var(--bgHover)] hover:active' to='skills' smooth={true} duration={200}>
              Skills
            </Link>
          </li>
          <li>
            <Link className='px-3 hover:text-[var(--bgHover)] hover:active' to='work' smooth={true} duration={200}>
              Projects
            </Link>
          </li>
          <li>
            <Link className='px-3 hover:text-[var(--bgHover)] hover:active' to='contact' smooth={true} duration={200}>
              Contact
            </Link>
          </li>
        </ul>
  
        {/* Hamburger */}
        <div onClick={handleClick} className='md:hidden z-10 hamburger me-6'>
          {!nav ? <FaBars /> : <FaTimes />}
        </div>
  
        {/* Mobile menu */}
        <ul
          className={
            !nav
              ? 'absolute -top-[600%] '
              : 'absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center mobile-menu' 
          }
        >
          <li className='py-6 text-4xl cursor-pointer'>
            <Link onClick={handleClick} to='home' smooth={true} duration={200}>
              Home
            </Link>
          </li>
          <li className='py-6 text-4xl cursor-pointer'>
            {' '}
            <Link onClick={handleClick} to='about' smooth={true} duration={200}>
              About
            </Link>
          </li>
          <li className='py-6 text-4xl cursor-pointer'>
            {' '}
            <Link onClick={handleClick} to='skills' smooth={true} duration={200}>
              Skills
            </Link>
          </li>
          <li className='py-6 text-4xl cursor-pointer'>
            {' '}
            <Link onClick={handleClick} to='projects' smooth={true} duration={200}>
              Projects
            </Link>
          </li>
          <li className='py-6 text-4xl cursor-pointer'>
            {' '}
            <Link onClick={handleClick} to='contact' smooth={true} duration={200}>
              Contact
            </Link>
          </li>
        </ul>
  
        {/* Social icons */}
        <div className='hidden lg:flex fixed flex-col top-[35%] left-0'>
          <ul>
            <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[0px] duration-300 bg-blue-600'>
              <a
                className='flex justify-between items-center w-full text-gray-300 ps-2'
                href='https://www.linkedin.com/in/casperpedersendotcom/'
              >
                Linkedin <FaLinkedin size={45} className='pe-2' />
              </a>
            </li>
            <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[0px] duration-300 bg-[#333333]'>
              <a
                className='flex justify-between items-center w-full text-gray-300 ps-2'
                href='https://github.com/cp361'
              >
                Github <FaGithub size={45} className='pe-2' />
              </a>
            </li>
            <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[0px] duration-300 bg-[#54687a]'>
              <a
                className='flex justify-between items-center w-full text-gray-300 ps-2'
                href='mailto:info@casperpedersen.com'
              >
                Email <HiOutlineMail size={45} className='pe-2' />
              </a>
            </li>
            <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[0px] duration-300 bg-[#490606]'>
              <a
                className='flex justify-between items-center w-full text-gray-300 ps-2'
                href={pdf} target='_blank' rel='noreferrer'
              >
                Resume <BsFillPersonLinesFill size={45} className='pe-2' />
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  };
  
  export default Navbar;