

export default [
    {
        id: 1,
        projectTitle: "camillapedersenterapi.dk",
        tag: "Psychologist",
        tag1: "WordPress",
        tag: "Psychologist",
        imageUrl: "camillapedersenterapi.png",
        link: "https://www.camillapedersenterapi.dk/",
        button: "View Site"
    },
    {
        id: 2,
        projectTitle: "gsbarbershop.dk",
        tag: "Barbershop",
        tag1: "WordPress",
        tag3: "",
        imageUrl: "gsbarbershop.png",
        link: "https://www.gsbarbershop.dk/",
        button: "View Site"
    },
]