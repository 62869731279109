import React from 'react'
import About from './About'
import Projects from './Projects'
import {HiArrowNarrowRight, HiArrowUp} from 'react-icons/hi'
import '../styles/projects.css';
import '../styles/hero.css';
import Contact from './Contact'
import { Link } from 'react-scroll'
import Skills from './Skills'
import ScrollToTop from "react-scroll-to-top";




export default function Home () {
    
    return (
        <>
            <section name='home' id='hero' className=' justify-center'>
                
                <h1 className='hero-headline'>
                    <span className='first-name '>CASPER</span>
                    <br/> PEDERSEN
                </h1>

                <div>
                    <Link to='work' smooth={true} duration={200}>
                        <button className='
                            text-white group mt-3 border-2 px-6 py-3 my-2 flex items-center hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)] xl:text-xl'>
                            View Work
                            <span className='group-hover:rotate-90 duration-300'><HiArrowNarrowRight className='ml-3'/></span>
                        </button>
                    </Link>
                </div>

                <ScrollToTop smooth  color='var(--bgHover)' width='30p' top={800} className='!bg-[var(--bgColor)]' />
            </section>

            <section>
                <About />
            </section>
            <section>
                <Skills />
            </section>
            <section>
                <Projects />
            </section>
            <section>
                <Contact />
            </section>
        </>
    )
}