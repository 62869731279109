import React from 'react'
import '../styles/school-projects.css'


const Semester2Items = (props) => {
  return (
    <div className={props.className}  >

            <img src={require(`../assets/${props.imageUrl}`)} class="img w-full p-3" alt=""></img>

              <div className="text-white">

                  <h3 className="py-3 text-4xl font-bold">{props.projectTitle}</h3>

                  <p className="tags m-auto overflow-hidden text-left md:flex md:flex-col">

                    <span className="accordion-description font-semibold text-xl">{props.subtitle}</span>
                    <br/>
                    <span 
                      className={props.descriptionOne}>
                        {props.description}
                    </span>
                    <br/>
                    <span 
                      className={props.descriptionTwo}>
                        {props.description2}
                    </span>
                    <br/>
                    <span 
                      className={props.descriptionThree}>
                        {props.description3}
                    </span>
                    <br/>
                    <span 
                      className={props.descriptionFour}>
                        {props.description4}
                    </span>

                  </p>

                  <div className={props.classNameButton}>

                    <a 
                      href={props.link} 
                      target="_blank" 
                      rel="noreferrer" 
                      className={props.classNameButton1}>
                      {props.button}
                    </a>
                    
                    <a 
                      href={props.link2} 
                      target="_blank" 
                      rel="noreferrer" 
                      className={props.classNameButton2}>
                      {props.button2}
                    </a>
                     
                  </div>

              </div>
      </div>
  )
}

export default Semester2Items