export default [
    {
        id: 1,

        className: "accordion-item mx-4 sm:mx-auto cards sm:me-2 bg-[rgba(210,199,199,0.15)]",
        
        projectTitle: "FLOW 1",

        subtitle: "UX Design: Bestilling af en oplevelse",

        descriptionStyles: "w-fit font-semibold text-xl",

        descriptionOne: "",
        descriptionTwo: "",
        descriptionThree: "",
        descriptionFour: "",

        description:
        "Det var vinterferie og vi fik stillet til opgave, at vi skulle designe en digital prototype, hvor vores målgruppe kan gennemføre en bestilling af en oplevelse.",

        description2: "Vores primære fokus skulle lægges på bestillingsløsningen, dog skulle der indgå andre features som understøtter vores målgruppes behov, herunder skulle vores bestillingsløsning rumme en eller anden form for mer’ salg, når målgruppen fortager deres bestilling af deres oplevelse.",

        description3: "Vores målgruppe var børnefamilier, hvor børnene er i skolealderen.",

        description4: "Se vores bud i nedenstående link.",


        imageUrl: "flow6img.png",

        link: "https://www.figma.com/proto/WRxB79CKh88PMRWrczdZrN/ReePark?node-id=149-9600&scaling=min-zoom&page-id=0%3A1&starting-point-node-id=47%3A335",

        link2: "",

        classNameButton: "flex sm:mt-[0px]",

        classNameButton1: "accordion-button me-1 hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)] button-padding",

        classNameButton2: "accordion-button ms-1 hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)] hidden",

        button: "Figma Prototype",

        button2: ""
    },
    {
        id: 2,

        className: "accordion-item mx-4 sm:mx-auto sm:ms-2 cards bg-[rgba(210,199,199,0.15)]",
        
        projectTitle: "FLOW 2",

        subtitle: "Frontend, Bootstrap & JavaScript",

        descriptionStyles: "w-fit font-semibold text-xl",

        descriptionOne: "",
        descriptionTwo: "",
        descriptionThree: "",
        descriptionFour: "",

        description:
        "Kantinen på Cphbusiness skulle have en nyt website. Vores opgave var at udvikle en prototype, der demonstrerede, hvordan dette website kunne se ud.",

        description2: "Vi bestemte selv, hvordan websitet skulle se ud.",

        description3: "Opgavens fokus var at få de tekniske dele til at fungere.",

        description4: "Se vores bud i nedenstående link.",


        imageUrl: "flow7img.png",

        link: "https://casperpedersen.com/CBAKantine/index.html",

        link2: "",

        classNameButton: "flex sm:mt-[0px] md:mt-[200px] xl:mt-[125px] semester-2-flow-2",

        classNameButton1: "accordion-button me-1 hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)] button-padding",

        classNameButton2: "accordion-button ms-1 hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)] hidden",

        button: "Figma Prototype",

        button2: ""
    },
    {
        id: 3,

        className: "accordion-item mx-4 sm:mx-auto cards sm:me-2 bg-[rgba(210,199,199,0.15)]",
        
        projectTitle: "FLOW 3",

        subtitle: "Content Creation – Video & InDesign",

        descriptionStyles: "w-fit font-semibold text-xl",

        descriptionOne: "",
        descriptionTwo: "",
        descriptionThree: "",
        descriptionFour: "",

        description:
        "I dette flow skulle vi arbejde med content med fokus på videoproduktion i Premiere Pro og plakater mm. i InDesign.",

        description2: "Vi skulle lave content til en ud af flere virksomheder, som vi samarbejdede med i flowet.",

        description3: "Min gruppe valgte at samarbejde med CPH Business Academy, med fokus på Multimediedesigner-linjen.",

        description4: "Se vores bud i nedenstående links.",


        imageUrl: "flow8img.png",

        link: "https://drive.google.com/file/d/12aVvRe4wCVc0wBIj9DSaQvqAYmBZfNNz/view?usp=sharing",

        link2: "https://drive.google.com/file/d/1DkNmcMSCrX-_eB2AdI63VXbVrctAgnJN/view?usp=sharing",

        classNameButton: "grid grid-cols-2 sm:mt-[0px]",

        classNameButton1: "accordion-button me-1 hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)] !pt-8 ",

        classNameButton2: "accordion-button ms-1 hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)] !py-[20px] ",

        button: "reklame video",

        button2: "projekt rapport"
    },
    {
        id: 4,

        className: "accordion-item mx-4 sm:mx-auto sm:ms-2 cards bg-[rgba(210,199,199,0.15)]",
        
        projectTitle: "FLOW 4",

        subtitle: "WordPress",

        descriptionStyles: "w-fit font-semibold text-xl",

        descriptionOne: "",
        descriptionTwo: "",
        descriptionThree: "",
        descriptionFour: "",

        description:
        "Vi fik tildelt en case, hvor vi skulle redesigne en hjemmeside, ud fra kundens ønsker.",

        description2: "Min gruppe blev tildelt tøjfirmaet Leve Copenhagen.",

        description3: "Efter et møde med kunden, gik vi igang med hjemmesiden.",

        description4: "Hjemmesiden er desværre blevet taget ned efterfølgende.",


        imageUrl: "flow9img.png",

        link: "/",

        link2: "",

        classNameButton: "flex sm:mt-[0px]",

        classNameButton1: "accordion-button me-1 hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)] button-padding md:mt-[100px] xl:mt-[50px]",

        classNameButton2: "accordion-button ms-1 hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)] hidden",

        button: "Leve Copenhagen Redesign",

        button2: ""
    },
    {
        id: 5,

        className: "accordion-item mx-4 sm:mx-auto cards sm:me-2 bg-[rgba(210,199,199,0.15)]",
        
        projectTitle: "FLOW 5",

        subtitle: "Eksamen",

        descriptionStyles: "w-fit font-semibold text-xl",

        descriptionOne: "",
        descriptionTwo: "",
        descriptionThree: "",
        descriptionFour: "",

        description:
        "I dette eksamens flow fik vi stillet opgaven om at opbygge en digital læreplatform, hvori vi skulle forsøge at inddrage brugeren.",

        description2: "Derudover skulle vi producere vores eget content til hjemmesiden. Så derfor gik min gruppe og jeg straks igang med brainstorm, og kom på ideén om en guide til tilflyttere eller turister til København.",

        description3: "For at producere vores eget content, legede vi turister for en dag eller to, i København, hvor vi filmede og tog en masse billeder.",

        description4: "Hjemmesiden er desværre blevet taget ned efterfølgende. Men tjek vores rapport ud!",


        imageUrl: "flow10img.png",

        link: "/",

        link2: "https://drive.google.com/file/d/1r15Y7JsoYM50l3_beHjXybOOwkjd0Bu6/view?usp=sharing",

        classNameButton: "flex sm:mt-[0px]",

        classNameButton1: "accordion-button me-1 hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)] hidden",

        classNameButton2: "accordion-button ms-1 hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)] button-padding",

        button: "",

        button2: "Eksamens Rapport"
    },
]