import React from 'react'

const Contact = () => {
  return (
    <div  className='w-full h-screen flex justify-center items-center p-4'>

        <form action='https://getform.io/f/1e47c255-af18-4943-9bf2-1086e8bc08e9' method='POST' className='flex flex-col max-w-[600px] w-full mb-48'>

            <div name='contact' className='pb-8'>
                <p className='section-title'>Contact</p>
                <p></p>
            </div>
            <input className='p-2 bg-[#ccd6f6] text-black' type='text' placeholder='Name' name='name'></input>
            <input className='my-4 p-2 bg-[#ccd6f6] text-black' type='email' placeholder='Email' name='email'></input>
            <textarea className='bg-[#ccd6f6] text-black p-2' name='message' rows='10' placeholder='Message'>
            </textarea>
            <button className='text-white border-2 hover:bg-[var(--bgHover)] hover:border-[var(--bgHover)] px-4 py-3 my-8 mx-auto flex items-center'>
                Send Message
            </button>
        </form>
        
    </div>
  )
}

export default Contact