import React from 'react'
import HTML from '../assets/html.png'
import CSS from '../assets/css.png'
import JavaScript from '../assets/javascript.png'
import ReactImg from '../assets/react.png'
import WordPress from '../assets/wordpress.png'
import Tailwind from '../assets/tailwind.png'
import PhotoShop from '../assets/photoshop.png'
import Illustrator from '../assets/illustrator.png'

const Skills = () => {
  return (
    <div  className='w-full mb-52  px-14'>
        <div className='container max-w-[1500px] mx-auto p-4 flex flex-col justify-center w-full h-full'>

            <div>

                <h1 name='skills' className='section-title py-4 inline'>Skills</h1>
                <p className='font-bold py-4 xl:text-xl'>These are the techonlogies I am familliar with.</p>

            </div>

            <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>
                <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500 xl:h-[200px] xl:pt-4'>
                    <img src={HTML} alt='HTML icon' className='w-20 mx-auto pt-4 xl:w-28'></img>
                    <p className='my-4'>HTML</p>
                </div>
                <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500 xl:h-[200px] xl:pt-4'>
                    <img src={CSS} alt='CSS icon' className='w-20 mx-auto pt-4 xl:w-28'></img>
                    <p className='my-4'>CSS</p>
                </div>
                <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500 xl:h-[200px] xl:pt-4'>
                    <img src={JavaScript} alt='JavaScript icon' className='w-20 mx-auto pt-4 xl:w-28'></img>
                    <p className='my-4'>JavaScript</p>
                </div>
                <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500 xl:h-[200px] xl:pt-4'>
                    <img src={ReactImg} alt='React icon' className='w-20 mx-auto pt-4 xl:w-28'></img>
                    <p className='my-4'>React</p>
                </div>
                <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500 xl:h-[200px] xl:pt-4'>
                    <img src={Tailwind} alt='Tailwind icon' className='w-20 mx-auto pt-4 xl:w-28'></img>
                    <p className='my-4'>Tailwind CSS</p>
                </div>
                <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500 xl:h-[200px] xl:pt-4'>
                    <img src={WordPress} alt='WordPress icon' className='w-20 mx-auto pt-4 xl:w-28'></img>
                    <p className='my-4'>WordPress</p>
                </div>
                <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500 xl:h-[200px] xl:pt-4'>
                    <img src={PhotoShop} alt='Photoshop icon' className='w-20 mx-auto pt-4 xl:w-28'></img>
                    <p className='my-4'>Photoshop</p>
                </div>
                <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500 xl:h-[200px] xl:pt-4'>
                    <img src={Illustrator} alt='Illustrator icon' className='w-20 mx-auto pt-4 xl:w-28'></img>
                    <p className='my-4'>Illustrator</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Skills